import React, { useEffect, useState } from 'react';
import '../../style/TestPool.css'; 
import { Switch } from '@mui/material';
import { ReactComponent as AddTestIcon } from '../../assets/pool.svg';
import { ReactComponent as IconTrash } from '../../assets/trash-pool.svg';
import { ReactComponent as IconEdit } from '../../assets/edit-pool.svg';
import { ReactComponent as IconFolder } from '../../assets/folder-pool.svg';
import { ReactComponent as IconTreeBools } from '../../assets/Ellipses.svg';
import PopupDeleteTest from './popups/popupDeleteTest';
import PopupAddNewTest from './popups/popupAddNewTest';
import { useDispatch, useSelector } from 'react-redux';
import { deleteTest, fetchTestResults, fetchTests, editTest, fetchIsActiveApp, updateSwitchValue } from '../../Services/TestService';
import { useNavigate } from 'react-router-dom';
import { getAverageScores } from '../../Services/TestService'; 


const TestPool = () => {
    const avgScore = require('../../assets/avg-score.jpg');
    const user = require('../../assets/User Plus.jpg');
    const calendar = require('../../assets/Calendar.jpg');
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [openAddNewTestPopup, setOpenAddNewTestPopup] = useState(false);
    const [editingTestId, setEditingTestId] = useState(null);
    const [newTitle, setNewTitle] = useState('');
    const [test, setTestId] = useState(0);
    const isActiveApp = useSelector(state => state.Test.isActiveApp);
    const [averageScores, setAverageScores] = useState({});
    const [hoveredTestId, setHoveredTestId] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Alltests = useSelector(state => state.Test.tests);
    const testResults = useSelector(state => state.Test.testResults);

    const handleDoubleClick = (test) => {
        navigate(`/setting`);
        if (localStorage.getItem("test")) {
            localStorage.removeItem("test");
        }
        const data = {
            testId: test.id,
            title: test.titleName
        };
        const jsonData = JSON.stringify(data);
        localStorage.setItem('test', jsonData);
    };

    const toggleDropdown = (id) => {
        setDropdownOpen(dropdownOpen === id ? null : id);
    };

    const handleOptionClick = (option, test) => {
        setSelectedOption(option);
        setDropdownOpen(null); 
        if (option === 'מחיקה') {
            setOpenDeletePopup(true);
            setTestId(test.id);
        } else if (option === 'שינוי שם') {
            setEditingTestId(test.id);
            setNewTitle(test.titleName);
        } else {
            navigate(`/setting`);
            if (localStorage.getItem("test")) {
                localStorage.removeItem("test");
            }
            const data = {
                testId: test.id,
                title: test.titleName
            };
            const jsonData = JSON.stringify(data);
            localStorage.setItem('test', jsonData);
        }
    };
    useEffect(() => {
        dispatch(fetchIsActiveApp('IsActiveApplication'));
    }, [dispatch]);

    const handleToggleSwitch = () => {
        const newValue = !isActiveApp;
        dispatch(updateSwitchValue('IsActiveApplication', newValue));
        dispatch({ type: 'SET_IS_ACTIVE_APP', payload: newValue });
    };
    const handleTitleChange = (event) => {
        setNewTitle(event.target.value);
    };

    const handleBlur = (id) => {
        const test = Alltests.find(test => test.id === id);
        const updatedTest = {
            id: test.id,
            dateOfTest: test.dateOfTest,
            isActive: test.isActive,
            titleName: newTitle
        };
        dispatch(editTest(id, updatedTest));
        setEditingTestId(null);
    };

    const handleClosePopupDelete = () => {
        setOpenDeletePopup(false);
    };

    const handlePopuoNewTest = () => {
        setOpenAddNewTestPopup(true);
    };

    const handleDeleteTest = () => {
        dispatch(deleteTest(test)) 
            .then(() => {
                handleClosePopupDelete(); 
            })
            .catch((error) => {
                console.error("Error deleting test:", error); 
            });
    };

    const handleCloseAddTestPopup = () => {
        setOpenAddNewTestPopup(false);
    };

    const latestActiveTest = Alltests
        .filter(test => test.isActive)
        .sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))[0];

    useEffect(() => {
        dispatch(fetchTests());
    }, [dispatch]);

    useEffect(() => {
        Alltests?.forEach(test => {
            dispatch(fetchTestResults(test.id));
            const fetchScores = async () => {
                try {
                    const data = await getAverageScores(test.id);
                    if (data) {
                        setAverageScores(prevScores => ({
                            ...prevScores,
                            [test.id]: data
                        }));
                    }
                } catch (error) {
                    console.log('Failed to fetch average scores');
                }
            };
            fetchScores();
        });
    }, [Alltests, dispatch]);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString('he-IL', options);
    };

    return (
        <div className="container-t-p">
            <div className="app-status-switch">
                <Switch
                    checked={isActiveApp}
                    onChange={handleToggleSwitch}
                    className="custom-switch"
                />
                {isActiveApp ? 'האפליקציה פעילה' : 'האפליקציה לא פעילה'}
            </div>
            <div className="frame1707478338-t-p">
                <div className="frame1366-t-p">
                    <div className="frame1000002375-t-p">
                        <button className="button-t-p" onClick={handlePopuoNewTest}>
                            מבחן חדש
                            <div className="buttonIcon-t-p">
                                <AddTestIcon className="vector-t-p" />
                            </div>
                        </button>
                        {openAddNewTestPopup && <div className='divOpenAddTestPopup'><PopupAddNewTest onClose={handleCloseAddTestPopup} /></div>}
                        <div className="titleText-t-p">מאגר מבחנים</div>
                    </div>
                    <div className="frame1151-t-p">
                        {Alltests?.map((test) => (
                            <div
                                className="folderCandidates-t-p"
                                key={test.id}
                                onMouseEnter={() => setHoveredTestId(test.id)} 
                                onMouseLeave={() => setHoveredTestId(null)} 
                            >
                                {test.id === latestActiveTest?.id ? (
                                    <div className="active-label">פעיל</div>
                                ) : (
                                    <div className="inactive-label">לא פעיל</div> 
                                )}
                                <button
                                    className="frame1327-t-p"
                                    onDoubleClick={() => handleDoubleClick(test)} 
                                >
                                    {hoveredTestId === test.id && ( 
                                        <IconTreeBools
                                            className="three-dots-icon-t-p"
                                            onClick={() => toggleDropdown(test.id)}
                                        />
                                    )}
                                    {dropdownOpen === test.id && (
                                        <div className="dropdown-t-p">
                                            <div
                                                className="dropdown-item-t-p"
                                                onClick={() => handleOptionClick('פתיחה', test)}
                                            >
                                                <IconFolder /> פתיחה
                                            </div>
                                            <div
                                                className="dropdown-item-t-p"
                                                onClick={() => handleOptionClick('שינוי שם', test)}
                                            >
                                                <IconEdit />  שינוי שם
                                            </div>
                                            <div
                                                className="dropdown-item-t-p"
                                                onClick={() => handleOptionClick('מחיקה', test)}
                                            >
                                                <IconTrash /> מחיקה
                                            </div>
                                        </div>
                                    )}
                                    {editingTestId === test.id ? (
                                        <input
                                            type="text"
                                            className="edit-title-input"
                                            value={newTitle}
                                            onChange={handleTitleChange}
                                            onBlur={() => handleBlur(test.id)}
                                            autoFocus
                                        />
                                    ) : (
                                        <div className="line1-t-p">{test.titleName}</div>
                                    )}
                                    <div className="three-dots-container-t-p"></div>
                                </button>
                                <div className="frame1326-t-p">
                                    <div className="frame1325-t-p">
                                        <div className="line2-t-p">
                                            {averageScores[test.id] ?
                                                `ציון ממוצע ${averageScores[test.id].averageFinalScore}`
                                                : 'Loading...'}
                                        </div>
                                        <div className="iconsOutline-t-p">
                                            <img src={avgScore} className="calendar-t-p" alt="Average Score" />
                                        </div>
                                    </div>
                                    <div className="frame1324-t-p">
                                        <div className="line2-t-p">
                                            {testResults[test.id] !== undefined ?
                                                `${testResults[test.id]} תלמידים`
                                                : 'Loading...'}
                                        </div>
                                        <div className="iconsOutline-t-p">
                                            <img src={user} className="userPlus-t-p" alt="User Plus" />
                                        </div>
                                    </div>
                                    <div className="frame1325-t-p">
                                        <div className="line2-t-p">{formatDate(test.dateOfTest)}</div>
                                        <div className="iconsOutline-t-p">
                                            <img src={calendar} className="calendar-t-p" alt="Calendar" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {openDeletePopup && (
                <div className="div-openDeletePopup">
                    <PopupDeleteTest onConfirm={handleDeleteTest} onClose={handleClosePopupDelete} />
                </div>
            )}
        </div>
    )
}

export default TestPool;
