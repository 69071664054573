
import React, { useState, useEffect } from 'react';
import '../../../style/Allpopup.css';
import { RichTextEditor } from '@mantine/rte';
import Checkbox from '@mui/material/Checkbox'; 
import FormControlLabel from '@mui/material/FormControlLabel'; 
import { sendCommentMark, getBodyOfLatestCommentMark } from '../../../Services/UserService';

const PopupSendComment = ({ open, onClose }) => {
    const sendIcon = require('../../../assets/send-msg.jpg');
    const msgIcon = require('../../../assets/msg.jpg');
    const downArrow = require('../../../assets/down-arrow.jpg');

    const [selectedTargets, setSelectedTargets] = useState([]); 
    const [selectedCategory, setSelectedCategory] = useState('');
    const [showCategoryOptions, setShowCategoryOptions] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true); 
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLatestComment = async () => {
            try {
                const testDisplay = localStorage.getItem("test");
                const testId = JSON.parse(testDisplay).testId;
                
                const latestComment = await getBodyOfLatestCommentMark(testId);
                if (latestComment) {
                    setMessage(latestComment);
                }
            } catch (err) {
                setError('Failed to fetch the latest comment.');
            } finally {
                setLoading(false);
            }
        };

        if (open) {
            fetchLatestComment();
        }
    }, [open]);

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        setSelectedTargets((prev) =>
            prev.includes(value) ? prev.filter((target) => target !== value) : [...prev, value]
        );
        setSelectedCategory('');
        setShowCategoryOptions(value === 'score-range');
    };

    const handleCategorySelect = (category) => {
        setSelectedCategory(category);
        setShowCategoryOptions(false);
    };

    const toggleCategoryOptions = () => {
        setShowCategoryOptions(!showCategoryOptions);
    };

    const handleSubmit = async () => {
        if (!message || selectedTargets.length === 0) {
            alert("Please select at least one target and enter a message.");
            return;
        }

        const testDisplay = localStorage.getItem("test");
        const testId = JSON.parse(testDisplay).testId;

        for (let target of selectedTargets) {
            if (target === 'students') {
                const mark = 100;
                const isPositive = false;
                const success1 = await sendCommentMark(testId, mark, isPositive, true, message);
                const success2 = await sendCommentMark(testId, mark, isPositive, false, message);

                if (!(success1 && success2)) {
                    alert("Failed to send comment to students.");
                    return;
                }
            } else if (target === 'score-range') {
                const mark = selectedCategory.includes('50 ומעלה') ? 50 : 49;
                const isPositive = selectedCategory.includes('ומעלה');
                const isStudent = selectedCategory.includes('לא למדו באיזילו') ? false : true;
                const success = await sendCommentMark(testId, mark, isPositive, isStudent, message);

                if (!success) {
                    alert("Failed to send comment to score range.");
                    return;
                }
            }
        }
        alert("Comment sent successfully!");
        onClose();
    };

    if (!open) return null;

    return (
        <div className="overlay-s-m" onClick={onClose}>
            <div className="popup-s-m" onClick={(e) => e.stopPropagation()}>
                <div className="header-s-m">
                    <h2 className="subtitle-s-m">כתיבת הערה לציון</h2>
                </div>
                <div className="section-s-m">
                    <h1 className="title-s-m">
                        <img src={sendIcon} alt="Send Icon" className="icon-s-m" />
                        למי תופיע ההערה?
                    </h1>
                    <div className="checkboxGroup-s-m">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="students"
                                    checked={selectedTargets.includes('students')}
                                    onChange={handleCheckboxChange}
                                    sx={{
                                        color: 'darkgray',
                                        '&.Mui-checked': {
                                            color: '#0C82AB',
                                        },
                                    }}
                                />
                            }
                            label="לכולם"
                            className={`checkboxLabel-s-m ${selectedTargets.includes('students') ? 'selected' : ''}`}
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    value="score-range"
                                    checked={selectedTargets.includes('score-range')}
                                    onChange={handleCheckboxChange}
                                    sx={{
                                        color: 'darkgray',
                                        '&.Mui-checked': {
                                            color: '#0C82AB',
                                        },
                                    }}
                                />
                            }
                            label={
                                <>
                                    <span onClick={toggleCategoryOptions} className="categoryText-s-m">
                                        {selectedCategory || 'לפי קטגוריות'}
                                    </span>
                                    <img src={downArrow} alt="Down Arrow" className="downArrow-s-m" />
                                </>
                            }
                            className={`checkboxLabel-s-m ${selectedTargets.includes('score-range') ? 'selected' : ''}`}
                        />
                        {showCategoryOptions && (
                            <div className="categoryOptionsContainer-s-m">
                                <div className="option-s-m" onClick={() => handleCategorySelect('ציון 50 ומעלה + לא למדו באיזילו')}>
                                    ציון 50 ומעלה + לא למדו באיזילו
                                </div>
                                <div className="option-s-m" onClick={() => handleCategorySelect('ציון 50 ומעלה + למדו באיזילו')}>
                                    ציון 50 ומעלה + למדו באיזילו
                                </div>
                                <div className="option-s-m" onClick={() => handleCategorySelect('ציון 49 ומטה + לא למדו באיזילו')}>
                                    ציון 49 ומטה + לא למדו באיזילו
                                </div>
                                <div className="option-s-m" onClick={() => handleCategorySelect('ציון 49 ומטה + למדו באיזילו')}>
                                    ציון 49 ומטה + למדו באיזילו
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="section-s-m">
                    <h1 className="title-s-m">
                        <img src={msgIcon} alt="Message Icon" className="icon-s-m" />
                        כתיבת הודעה
                    </h1>
                    {loading ? (
                        <div>Loading comment...</div>
                    ) : error ? (
                        <div>{error}</div>
                    ) : (
                        <RichTextEditor
                            value={message}
                            onChange={setMessage}
                            controls={[
                                ['bold', 'italic', 'underline', 'link'],
                                ['unorderedList', 'orderedList'],
                                ['alignLeft', 'alignCenter', 'alignRight'],
                                ['highlight']
                            ]}
                            className="textField-p-s-c"
                            placeholder="הכנס תוכן הודעה"
                        />
                    )}
                </div>
                <div className="buttonGroup-s-m">
                    <button className="cancel-button-p-s-c" onClick={onClose}>
                        ביטול
                    </button>
                    <button className="approval-button-p-s-c" onClick={handleSubmit}>
                        שמירה
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PopupSendComment;
