
import React, { useEffect, useState } from 'react';
import '../../style/RecieveMark.css';
import { getCommentForLatestTestAndUserId } from '../../Services/UserService';
import { getExamVersionsByPhone } from '../../Services/UserService';
import { convertToCSV, downloadCSV, fetchSwitchValue, fetchTests, getEndScore, getUserExamQuestionsAndAnswers, updateScores } from '../../Services/TestService';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PopupLogout from '../Admin/popups/popupLogOut';


const RecieveMark = () => {
    const [score, setScore] = useState(null);
    const [displayedScore, setDisplayedScore] = useState(0);
    const [userName, setUserName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [comment, setComment] = useState(null);
    const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
    const [versions, setVersions] = useState({ procedural: null, substantive: null });
    const [animate, setAnimate] = useState(false);
    const [shouldLogout, setShouldLogout] = useState(false);

    useEffect(() => {
        if (shouldLogout) {
            navigate('/');
            setShouldLogout(false);
        }
    }, [shouldLogout]);
    const logoImage = require('../../assets/easylaw-logo-01.jpg');
    const logoImage2 = require('../../assets/easylaw-logo-01 copy.png');
    const book = require('../../assets/Group 1.png');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const Alltests = useSelector(state => state.Test.tests);

    useEffect(() => {
        dispatch(fetchTests());
    }, [dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => setAnimate(true), 1000);
        return () => clearTimeout(timer);
    }, []);
    const latestActiveTest = Alltests
        .filter(test => test.isActive)
        .sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest))[0];

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };


    useEffect(() => {
        const fetchUser = () => {
            const userFromStorage = localStorage.getItem("user");
            let parsedUser = null;

            if (userFromStorage) {
                parsedUser = JSON.parse(userFromStorage);
            } else {
                const userFromCookies = getCookie("user");
                if (userFromCookies) {
                    parsedUser = JSON.parse(userFromCookies);
                }
            }

            if (parsedUser) {
                setUserName(parsedUser.name);
                setUserId(parsedUser.id);
            }
        };
        fetchUser();
    }, []);

    useEffect(() => {
        if (userId && latestActiveTest) {
            fetchScoreAndComment();
        }
    }, [userId, latestActiveTest]);

    useEffect(() => {
        if (score !== null) {
            let currentScore = 1;
            const increment = Math.ceil(score / 50);
            const interval = setInterval(() => {
                if (currentScore >= score) {
                    clearInterval(interval);
                    setDisplayedScore(score);
                } else {
                    currentScore += increment;
                    setDisplayedScore(currentScore);
                }
            }, 20);
        }
    }, [score]);


    const fetchScoreAndComment = async () => {
        if (userId && latestActiveTest) {
            try {
                await updateScores({ UserId: userId, TestId: latestActiveTest.id });
                const endScore = await getEndScore(userId, latestActiveTest.id);
                setScore(Number(endScore));

                const fetchedComment = await getCommentForLatestTestAndUserId(userId);
                setComment(fetchedComment);

                // שליפת גרסאות
                const phone = JSON.parse(localStorage.getItem("user") || getCookie("user")).phone;
                console.log("phone", phone);
                const versionsData = await getExamVersionsByPhone(phone);
                console.log("versionsData", versionsData);
                setVersions({
                    procedural: versionsData.proceduralVersion,
                    substantive: versionsData.substantiveVersion,
                });
            } catch (error) {
                console.error('Failed to fetch score, comment, or versions:', error);
            }
        }
    };

    const handleDownload = async (userId) => {
        try {
            const data = await getUserExamQuestionsAndAnswers(latestActiveTest.id, userId);
            const csvContent = convertToCSV(data);

            const blob = new Blob([csvContent], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);

            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(blob, 'exam_questions_and_answers.csv');
            } else if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
                const newWindow = window.open(url);
                if (!newWindow) {
                    alert('לא ניתן לפתוח את הקובץ. נא לוודא שהדפדפן תומך.');
                }
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'exam_questions_and_answers.csv');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };
    const handleMoveToLandingPage = () => {
        navigate('/user/test')
    }

    // return (
    //     <div className="container-m">
    //         <div className="header-m">
    //             <img className="logo-m" src={logoImage} alt="Logo" />
    //         </div>
    //         <div className="content-m">
    //             <img src={book} alt="ספר" />

    //             <div className="main-score-m">
    //                 <div className="greeting-m">הי, {userName}</div>
    //                 <div className="estimated-score-m">הציון המשוער שלך</div>
    //                 <h3 className='title-score-m'>בשקלול הדין הדיוני והדין המהותי הוא:</h3>
    //                 <div className="score-m">{displayedScore}</div>
    //                 <div className="test-versions-m">
    //                     <div className='txt-version'>
    //                         גירסת המבחן שלך בדין דיוני היא: <strong>{versions.procedural}</strong>
    //                     </div>
    //                     <div className='txt-version'>
    //                         גירסת המבחן שלך בדין מהותי היא: <strong>{versions.substantive}</strong>
    //                     </div>
    //                 </div>
    //             </div>
    //             {/* <button className='downCsv' onClick={() => handleDownload(userId)}>לפתרונות שלך</button> */}
    //             {comment && (
    //                 <div className="help-box-m" dangerouslySetInnerHTML={{ __html: comment }} />
    //             )}
    //             <div className="home-link-m" onClick={() => window.location.href = "https://easylaw.io"}>לדף הבית &gt;&gt;</div>
    //         </div>
    //     </div>
    // );


    const handleLogoutClick = () => {
        setLogoutPopupOpen(true);
    };
    const deleteCookie = (name) => {
        document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    };
    const handleLogout = () => {
        localStorage.clear();
        deleteCookie("user");
        setLogoutPopupOpen(false);
        setShouldLogout(true);
    };
    const closeLogoutPopup = () => {
        setLogoutPopupOpen(false);
    };
    if (versions.procedural === 0 || versions.substantive === 0) {
        return (<>
            <div className="inactive-message-container">
                <div className="logo-sign">
                    <img className="logo-img-sign" src={logoImage2} alt="Logo" />
                </div>
                <div className="inactive-message">
                    <p>נראה שלא פתרת את הבחינה</p>

                    <p>
                        כדי לצפות בציון יש להזין תוצאות
                        <a
                            onClick={handleMoveToLandingPage}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`animated-link ${animate ? 'animate' : ''}`}
                        > כאן</a>.
                    </p>
                </div>
            </div></>
        );
    }

    return (
        <div className="container-m">
            <button
                className="logout-button"
                onClick={handleLogoutClick}
            >
                <LogoutIcon className="LogoutIcon" style={{ fontSize: '18px' }} />
                התנתקות
            </button>
            <div className="header-m">
                <img className="logo-m" src={logoImage} alt="Logo" />
            </div>
            <div className="content-m">
                <img src={book} alt="ספר" />
                <div className="main-score-m">
                    <div className="greeting-m">הי, {userName}</div>
                    <div className="estimated-score-m">הציון המשוער שלך</div>
                    <h3 className='title-score-m'>בשקלול הדין הדיוני והדין המהותי הוא:</h3>
                    <div className="score-m">{displayedScore}</div>
                    <div className="test-versions-m">
                        <div className='txt-version'>
                            גירסת המבחן שלך בדין דיוני היא: <strong>{versions.procedural}</strong>
                        </div>
                        <div className='txt-version'>
                            גירסת המבחן שלך בדין מהותי היא: <strong>{versions.substantive}</strong>
                        </div>
                    </div>
                </div>
                {comment && (
                    <div className="help-box-m" dangerouslySetInnerHTML={{ __html: comment }} />
                )}
                <div className="home-link-m" onClick={() => window.location.href = "https://easylaw.io"}>לדף הבית &gt;&gt;</div>
            </div>
            <PopupLogout
                open={isLogoutPopupOpen}
                onClose={closeLogoutPopup}
                onConfirm={handleLogout}
            />
        </div>
    );
};

export default RecieveMark;
