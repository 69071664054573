

// import React, { useEffect, useState } from 'react';
// import '../style/LandingPage.css';
// import { useNavigate } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchIsActiveApp, fetchSwitchValue, fetchTests, hasUserCompletedTest } from '../Services/TestService';



// const LandingPage = () => {
//   const logoImage = require('../../src/assets/easylaw-logo-01.jpg');
//   const logoImage2 = require('../../src/assets/easylaw-logo-01 copy.png');
//   const arrow_left = require('../../src/assets/Alt Arrow Left.png');
//   const [title, setTitle] = useState('');
//   const [animate, setAnimate] = useState(false);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const isActiveApp = useSelector(state => state.Test.isActiveApp);
//   const isSwitchActive = useSelector(state => state.Test.isSwitchActive);
//   console.log("isSwitchActive:", isSwitchActive);
//   console.log("isActiveApp", isActiveApp);
//   const Alltests = useSelector(state => state.Test.tests);
//   console.log("Alltests", Alltests);

//   if (Alltests && Alltests.length > 0) {
//     // מציאת המבחן עם ה-id הגבוה ביותר
//     const lastTest = Alltests.reduce((prev, current) => 
//       current.id > prev.id ? current : prev
//     );
//     console.log("Last Test ID:", lastTest.id);
//   } else {
//     console.log("No tests available");
//   }
//   useEffect(()=>{
//     if (Alltests && Alltests.length > 0) {
//       // מציאת המבחן עם ה-id הגבוה ביותר
//       const lastTest = Alltests.reduce((prev, current) => 
//         current.id > prev.id ? current : prev
//       );
//       console.log("Last Test ID:", lastTest.id);
//     } else {
//       console.log("No tests available");
//     }
//   },[Alltests])
//   useEffect(() => {
//     const timer = setTimeout(() => setAnimate(true), 1000);
//     return () => clearTimeout(timer);
//   }, []);


//   // useEffect(() => {
//   //   dispatch(fetchIsActiveApp('IsActiveApplication'));
//   // }, [dispatch]);

//   // useEffect(() => {
//   //   dispatch(fetchSwitchValue('Switch'));
//   // }, [dispatch]);

//   // useEffect(() => {
//   //   dispatch(fetchTests());
//   // }, [dispatch]);

//   useEffect(() => {
//     // Fetch all required data
//     dispatch(fetchIsActiveApp('IsActiveApplication'));
//     dispatch(fetchSwitchValue('Switch'));
//     dispatch(fetchTests());
//   }, [dispatch]);
  

//   const handleEnterTest = () => {
//     localStorage.removeItem('discussionQuestions');
//     localStorage.removeItem('VersionOfDiscussion');
//     localStorage.removeItem('SubstantiveQuestions');
//     localStorage.removeItem('VersionOfSubstantive');
//     navigate('/user/test');
//   }


//   const getLastTestTitle = async () => {
//     const sortedTests = Alltests.sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest));
//     const lastTest = sortedTests[0];

//     if (lastTest) {
//       return lastTest.titleName;
//     } else {
//       console.log("No tests available");
//       return null;
//     }
//   };

//   // const  getLastTestId=async()=>{
//   //   const sortedTests = Alltests.sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest));
//   //   const lastTest = sortedTests[0];

//   //   if (lastTest) {
//   //     setTestId(lastTest.id)
//   //   } else {
//   //     console.log("No tests available");
//   //     return null;
//   //   }
//   // }
//   const getCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(';').shift();
//     return null;
//   };



//   const handleRemember = async () => {
//     const userData = getCookie('user');
//     if (userData) {
//       const user = JSON.parse(userData);
//       console.log("user!!!!!!!",user)
//       if (!user || isSwitchActive === undefined) return;
//       if (user.permissionId === 1 || user.permissionId === 2) {
//         navigate('/admin/poolOfTest');
//       } else if (user.permissionId === 3) {
//         try {
//           console.log('lastTest.id',lastTest.id);
//           console.log("user.id",user.id)
//           const hasCompleted = await hasUserCompletedTest(lastTest.id, user.id);
//           console.log("hasCompletedddddddddddddddddddddddddddddddddddddddd",hasCompleted);
//           if (hasCompleted) {
//             if (isSwitchActive) {
//               navigate('/user/test/mark');
//             } else {
//               navigate('/user/test/finish');
//             }
//           } else {
//             navigate('/');
//           }
//         } catch (error) {
//           console.error('Error checking if user completed test:', error);
//         }
//       }
//     }
//   }
//   useEffect(() => {
//     dispatch(fetchSwitchValue('Switch')).then(() => {
//       handleRemember()
//     })
//   }, [navigate, isSwitchActive])

//   const fetchTitle = async () => {
//     const titleOfTest = await getLastTestTitle();
//     setTitle(titleOfTest);
//     console.log("Title of the last test:", titleOfTest);
//   };

//   fetchTitle();

//   if (!isActiveApp) {
//     return (
//       <div className="inactive-message-container">
//         <div className="logo-sign">
//           <img className="logo-img-sign" src={logoImage2} alt="Logo" />
//         </div>
//         <div className="inactive-message">
//           <p>המערכת פעילה בסמוך למועדי בחינות הלשכה.</p>
//           <p>
//             לרישום לקורס ההכנה של איזילו לבחינות הלשכה יש ללחוץ
//             <a
//               href="https://hi.easylaw.io/bar"
//               target="_blank"
//               rel="noopener noreferrer"
//               className={`animated-link ${animate ? 'animate' : ''}`}
//             > כאן</a>.
//           </p>
//         </div>
//       </div>
//     );

//   }
//   return (
//     <div className="figma-container-landing">
//       <div className="frame1707478343-landing">
//         <div className="frame21-landing">
//           <img className="easylaw-logo-011-landing" src={logoImage} alt="Easylaw Logo" />
//         </div>
//         <div className="frame1707478210-landing">
//           <div className="frame14-landing">
//             <div className="title-landing">ציון משוער</div>
//             <div className="frame16-landing">
//               <div className="title-landing">
//                 <div className="exam-date-landing">{title}</div>
//               </div>
//             </div>
//           </div>
//           <div className="frame22-landing">
//             <div className="frame16-landing">
//               <div className="title-landing">
//                 <div className="easylaw41-landing">
//                   <span>
//                     מזינים תשובות ומקבלים ציון משוער
//                     <br />
//                   </span>
//                   <span className='text-easy'>
//                     הציון המשוער מבוסס על פתרון מטעם הצוות המקצועי של חברת EASYLAW
//                     <br />
//                     תיתכן סטייה של 4-1 נקודות בין הציון המשוער לציון לאחר הפתרון הרשמי.
//                   </span>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="frame1707478411-landing">
//             <button className="button-landing-1" onClick={() => handleEnterTest()}>
//               <img src={arrow_left} alt="חץ שמאלה" className="arrow-left" />
//               המשך להזנת התשובות
//             </button>
//             <div className="button-landing-2">
//               <div className="button-master-landing">
//                 <div className="button-login-landing">
//                   <div>
//                     <span>כבר הזנת?</span>
//                     <span className="login-link" onClick={() => { navigate('/login') }}>התחברות</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LandingPage;
import React, { useEffect, useState } from 'react';
import '../style/LandingPage.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchIsActiveApp, fetchSwitchValue, fetchTests, hasUserCompletedTest } from '../Services/TestService';

const LandingPage = () => {
  const logoImage = require('../../src/assets/easylaw-logo-01.jpg');
  const logoImage2 = require('../../src/assets/easylaw-logo-01 copy.png');
  const arrow_left = require('../../src/assets/Alt Arrow Left.png');

  const [title, setTitle] = useState('');
  const [animate, setAnimate] = useState(false);
  const [lastTest, setLastTest] = useState(null); // הוספת state לשמירת lastTest
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const isActiveApp = useSelector(state => state.Test.isActiveApp);
  const isSwitchActive = useSelector(state => state.Test.isSwitchActive);
  const Alltests = useSelector(state => state.Test.tests);
  
  console.log("isSwitchActive:", isSwitchActive);
  console.log("isActiveApp", isActiveApp);
  console.log("Alltests", Alltests);
  
  useEffect(() => {
    if (Alltests && Alltests.length > 0) {
      // מציאת המבחן עם ה-id הגבוה ביותר
      const lastTest = Alltests.reduce((prev, current) => 
        current.id > prev.id ? current : prev
      );
      setLastTest(lastTest); // עדכון state עם lastTest
      console.log("Last Test ID:", lastTest.id);
    } else {
      console.log("No tests available");
    }
  }, [Alltests]);

  useEffect(() => {
    const timer = setTimeout(() => setAnimate(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    // Fetch all required data
    dispatch(fetchIsActiveApp('IsActiveApplication'));
    dispatch(fetchSwitchValue('Switch'));
    dispatch(fetchTests());
  }, [dispatch]);

  const handleEnterTest = () => {
    localStorage.removeItem('discussionQuestions');
    localStorage.removeItem('VersionOfDiscussion');
    localStorage.removeItem('SubstantiveQuestions');
    localStorage.removeItem('VersionOfSubstantive');
    navigate('/user/test');
  };

  const getLastTestTitle = async () => {
    const sortedTests = Alltests.sort((a, b) => new Date(b.dateOfTest) - new Date(a.dateOfTest));
    const lastTest = sortedTests[0];

    if (lastTest) {
      return lastTest.titleName;
    } else {
      console.log("No tests available");
      return null;
    }
  };

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const handleRemember = async () => {
    const userData = getCookie('user');
    if (userData) {
      const user = JSON.parse(userData);
      if (!user || isSwitchActive === undefined) return;
      
      if (user.permissionId === 1 || user.permissionId === 2) {
        navigate('/admin/poolOfTest');
      } else if (user.permissionId === 3) {
        try {
          if (!lastTest) {
            console.error('No last test available');
            return;
          }
          const hasCompleted = await hasUserCompletedTest(lastTest.id, user.id);
          if (hasCompleted) {
            if (isSwitchActive) {
              navigate('/user/test/mark');
            } 
            else 
            {
              navigate('/user/test/finish');
            }
          } else {
            navigate('/');
          }
        } catch (error) {
          console.error('Error checking if user completed test:', error);
        }
      }
    }
  }
  

  useEffect(() => {
    dispatch(fetchSwitchValue('Switch')).then(() => {
      handleRemember();
    });
  }, [navigate, isSwitchActive, lastTest]); // הוספנו את lastTest כאן, כך שהפונקציה תופעל אם lastTest משתנה

  const fetchTitle = async () => {
    const titleOfTest = await getLastTestTitle();
    setTitle(titleOfTest);
    console.log("Title of the last test:", titleOfTest);
  };

  fetchTitle();

  if (!isActiveApp) {
    return (
      <div className="inactive-message-container">
        <div className="logo-sign">
          <img className="logo-img-sign" src={logoImage2} alt="Logo" />
        </div>
        <div className="inactive-message">
          <p>המערכת פעילה בסמוך למועדי בחינות הלשכה.</p>
          <p>
            לרישום לקורס ההכנה של איזילו לבחינות הלשכה יש ללחוץ
            <a
              href="https://hi.easylaw.io/bar"
              target="_blank"
              rel="noopener noreferrer"
              className={`animated-link ${animate ? 'animate' : ''}`}
            > כאן</a>.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="figma-container-landing">
      <div className="frame1707478343-landing">
        <div className="frame21-landing">
          <img className="easylaw-logo-011-landing" src={logoImage} alt="Easylaw Logo" />
        </div>
        <div className="frame1707478210-landing">
          <div className="frame14-landing">
            <div className="title-landing">ציון משוער</div>
            <div className="frame16-landing">
              <div className="title-landing">
                <div className="exam-date-landing">{title}</div>
              </div>
            </div>
          </div>
          <div className="frame22-landing">
            <div className="frame16-landing">
              <div className="title-landing">
                <div className="easylaw41-landing">
                  <span>
                    מזינים תשובות ומקבלים ציון משוער
                    <br />
                  </span>
                  <span className='text-easy'>
                    הציון המשוער מבוסס על פתרון מטעם הצוות המקצועי של חברת EASYLAW
                    <br />
                    תיתכן סטייה של 4-1 נקודות בין הציון המשוער לציון לאחר הפתרון הרשמי.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="frame1707478411-landing">
            <button className="button-landing-1" onClick={() => handleEnterTest()}>
              <img src={arrow_left} alt="חץ שמאלה" className="arrow-left" />
              המשך להזנת התשובות
            </button>
            <div className="button-landing-2">
              <div className="button-master-landing">
                <div className="button-login-landing">
                  <div>
                    <span>כבר הזנת?</span>
                    <span className="login-link" onClick={() => { navigate('/login') }}>התחברות</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
