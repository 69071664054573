

import React, { useEffect, useState } from 'react';
import '../../style/finishWindow.css';
import { useLocation, useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import PopupLogout from '../Admin/popups/popupLogOut';

const FinishWindow = () => {
  const logoImage = require('../../assets/easylaw-logo-01.jpg');
  const star = require('../../assets/OBJECTS.jpg')
  const navigate =useNavigate();
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const [shouldLogout, setShouldLogout] = useState(false);

  useEffect(() => {
    if (shouldLogout) {
      navigate('/');
      setShouldLogout(false);
    }
  }, [shouldLogout]);
  const handleLogoutClick = () => {
    setLogoutPopupOpen(true);
  };

  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const handleLogout = () => {
    localStorage.clear();
    deleteCookie("user");
    setLogoutPopupOpen(false);
    // navigate('/');
    setShouldLogout(true);
  };

  const closeLogoutPopup = () => {
    setLogoutPopupOpen(false);
  };
 
  return (
    <div className="container-f-w">
         <button
          className="logout-button"
          onClick={handleLogoutClick}
        >
          <LogoutIcon className="LogoutIcon" style={{ fontSize: '18px' }} />
          התנתקות
        </button>
      <div className="frame21-f-w">
        <img className="easylawLogo-f-w" src={logoImage} alt="Easylaw Logo" />
      </div>

        <img src={star} className="vector-f-w" />
      <div className="frame1707478413-f-w">
          <div className="largeText-f-w">
            אנחנו על זה
          </div>
      </div>
          <div className="easylawSms--f-w">
            הצוות המקצועי של EASYLAW פותר ברגעים אלה את הבחינה.
            <br />
            ברגע שהפתרון יסתיים נשלח לך הודעת SMS עם לינק לצפייה בציון המשוער.
          </div>
          <PopupLogout
        open={isLogoutPopupOpen}
        onClose={closeLogoutPopup}
        onConfirm={handleLogout}
      />
    </div>
  );
};

export default FinishWindow;
