import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, TextField, RadioGroup, FormControlLabel, Radio, FormLabel } from '@mui/material';
import { addUser, updateAdmin } from '../../../Services/UserService';
import '../../../style/displayAllAdmin.css';


const AddOrEditAdmin = ({ open, onClose, admin, editing, onUpdate }) => {
    const [newAdmin, setNewAdmin] = useState({
        name: '',
        phone: '',
        email: '',
        permissionId: '1',
        permission: { id: 2, name: 'Admin' },
    });

    useEffect(() => {
        if (editing && admin) {
            setNewAdmin({
                name: admin.name || '',
                phone: admin.phone || '',
                email: admin.email || '',
                permissionId: admin.permissionId ? admin.permissionId.toString() : '1',
                permission: {
                    id: admin.permissionId || 1,
                    name: admin.permissionId === 1 ? 'Super Admin' : 'Admin'
                }
            });
        } else {
            setNewAdmin({
                name: '',
                phone: '',
                email: '',
                permissionId: '1',
                permission: { id: 1, name: 'Super Admin' }
            });
        }
    }, [admin, editing]); 
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === 'permissionId') {
            setNewAdmin((prev) => ({
                ...prev,
                permissionId: value,
                permission: { id: value, name: value === '1' ? 'Super Admin' : 'Admin' }
            }));
        } else {
            setNewAdmin((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleSubmit = async () => {
        if (editing) {
            await updateAdmin(admin.id, newAdmin);
        } else {
            await addUser(newAdmin);
        }
        onUpdate();
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} style={{ direction: 'rtl' }}>
            <DialogTitle>{editing ? 'עדכון פרטי אדמין' : 'הוסף אדמין חדש'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {editing ? 'עדכן את הפרטים של האדמין.' : 'אנא מלא את הפרטים הבאים כדי להוסיף אדמין חדש.'}
                </DialogContentText>
                <TextField autoFocus margin="dense" name="name" label="שם" fullWidth value={newAdmin.name} onChange={handleInputChange} inputProps={{ style: { direction: 'rtl' } }} required />
                <TextField name="phone" label="טלפון" fullWidth value={newAdmin.phone} onChange={handleInputChange} inputProps={{ style: { direction: 'rtl' } }} required />
                <TextField margin="dense" name="email" label="אימייל" fullWidth value={newAdmin.email} onChange={handleInputChange} inputProps={{ style: { direction: 'rtl' } }} required />
                <FormLabel component="legend">סוג תפקיד</FormLabel>
                <RadioGroup aria-label="permissionId" name="permissionId" value={newAdmin.permissionId} onChange={handleInputChange} style={{ direction: 'rtl' }}>
                    <FormControlLabel value="1" control={<Radio className="custom-radio" />} label="Super Admin" />
                    <FormControlLabel value="2" control={<Radio className="custom-radio" />} label="Admin" />
                </RadioGroup>
            </DialogContent>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '3%' ,marginLeft:'1.5vw'}}>
                <button className='btn-cancel-add-admin' onClick={onClose}>ביטול</button>
                <button className='btn-approve-add-admin' onClick={handleSubmit}>
                    {editing ? 'עדכן' : 'הוסף'}
                </button>
            </div>


        </Dialog>
    );
};

export default AddOrEditAdmin;
