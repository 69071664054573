import axios from 'axios';
import { REGISTER, LOG_OUT } from '../redux/action';

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL;

export const registerUser = (userData, isEmail) => async (dispatch) => {
    try {
        const response = await axios.post(`${baseUrl}/User/Register?isEmail=${isEmail}`, userData);
        console.log("response",response)
        dispatch({ type: REGISTER, payload: response.data });
        return { success: true, message: response.data.message };
    } catch (error) {
        const errorMessage =
            error.response?.data?.message || "An unexpected error occurred.";
        return { success: false, message: errorMessage };
    }
};


export const loginUser = async (phone) => {
    try {
        // שליחת בקשה ל-API עם המידע (טלפון או אימייל)
        const response = await axios.post(`${baseUrl}/User/Login`, null, {
            params: {
                phone: phone, // המידע שנשלח (טלפון או אימייל)
            }
        });

        // אם הבקשה הצליחה, מחזירים את ההודעה מהשרת
        return response.data;
    } catch (error) {
        // אם קרתה שגיאה, מחזירים את השגיאה
        console.error('Error during login:', error);
        return { message: 'An error occurred during login.' };
    }
};
export const verifyCode = async (phoneNumber, code) => {
    try {
        const response = await axios.post(`${baseUrl}/SendSms/verify-code`, {
            "phoneNumber": phoneNumber,
            "code": code
        });
        console.log(" res in verify",response)
        return response.data; 
    } catch (error) {
        console.error('Error verifying code:', error);
        return { success: false, message: error.message || 'An error occurred.' };
    }
};

export const getExamVersionsByPhone = async (phone) => {
    try {
        const response = await axios.get(`${baseUrl}/User/exam-versions-by-phone`, {
            params: { phone }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching exam versions by phone:', error);
        throw error;
    }
}
export const logoutUser = () => dispatch => {
    dispatch({ type: LOG_OUT });
};


export const addTestToUser = (userId, versionOfDiscussion, versionOfSubstantive, discussionQuestions, substantiveQuestions) => {

    return async (dispatch) => {
        try {
            console.log("userId", userId, "versionOfDiscussion", versionOfDiscussion, "versionOfSubstantive", versionOfSubstantive, "discussionQuestions", discussionQuestions, "substantiveQuestions", substantiveQuestions)
            const response = await axios.post(`${baseUrl}/User/addTestToUser`, {
                "userId": userId,
                "versionOfDiscussion": versionOfDiscussion,
                "versionOfSubstantive": versionOfSubstantive,
                "discussionQuestions": discussionQuestions,
                "substantiveQuestions": substantiveQuestions
            });
            if (response.data.success) {
                return { success: true };
            }
            else {
                return { success: false, message: response.data.message };
            }
        } catch (error) {
            console.log('Error adding test to user');
            return { onSuccess: false, error: error.message };
        }
    };
};

export const sendSms = async (smsData) => {
    console.log('smsData:', smsData)
    try {
        const response = await axios.post(`${baseUrl}/SendSms/send`, smsData);
        return response.data;
    } catch (error) {
        console.error('Error sending SMS:', error);
        throw error;
    }
};

export const fetchAllMessages = async () => {
    try {
        const response = await axios.get(`${baseUrl}/SendSms/saved-messages`);
        return response.data;
    } catch (error) {
        console.error('Error fetching messages:', error);
        throw error;
    }
};

export const saveMessage = async (title, message) => {
    try {
        const response = await axios.post(`${baseUrl}/SendSms/saveMessage`, {
            title,
            message,
        });
        return response.data; 
    } catch (error) {
        console.error('Error saving message:', error);
        throw error.response?.data || error.message || 'Failed to save message.';
    }
};

export const fetchPhoneNumbers = async (testId, isStudent) => {

    try {
        const response = await axios.get(`${baseUrl}/User/GetPhonesByTestAndStudentStatus?testId=${testId}&isStudent=${isStudent}`);
        console.log('Fetched phone numbers:', response.data); 
        return response.data; 
    } catch (error) {
        console.error('There was an error fetching the phone numbers!', error);
        throw error;
    }
};

export const fetchPhoneNumbersByScoreRange = async (testId, minScore, maxScore) => {
    try {
        const response = await axios.get(`${baseUrl}/User/phones`, {
            params: {
                testId: testId,
                minScore: minScore,
                maxScore: maxScore
            }
        })
        console.log('Fetched phone numbers:', response.data); 
        return response.data;
    } catch (error) {
        console.error('There was an error fetching the phone numbers!', error);
        throw error;
    }
}

export const sendCommentMark = async (testId, mark, isPositive, isStudent, bodyOfComment) => {
    try {
        const requestBody = {
            "testId": testId,
            "mark": mark,
            "isPositive": isPositive,
            "isOurStudent": isStudent,
            "bodyOfComment": bodyOfComment
        };

        const response = await axios.post(`${baseUrl}/Test/assign-comment-mark`, requestBody);

        return response.status === 200;
    } catch (error) {
        console.error('Error:', error);
        return false;
    }
};

export const calculateExamScores = async (testId, userId) => {
    try {
        const response = await axios.post(`${baseUrl}/Test/calculate-exam-scores`, {
            testId,
            userId,
        });
        console.log(response.data, "endScore");
        return response.data.endScore; 
    } catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || 'Failed to calculate exam scores.');
        }
        console.error('Error calculating exam scores:', error);
        throw error;
    }
};

export const getCommentForLatestTestAndUserId = async (userId) => {
    try {
        const response = await axios.get(`${baseUrl}/User/comment-for-latest-test/${userId}`, {
            params: { userId }
        });
        console.log("bodyOfComment", response.data.comment);
        return response.data.comment;
    }
    catch (error) {
        if (error.response && error.response.data) {
            throw new Error(error.response.data.message || 'Failed to calculate exam scores.');
        }
        console.error('Error calculating exam scores:', error);
        throw error;
    }
};

export const getUserByPhone = async (phone) => {
    try {
        const response = await axios.get(`${baseUrl}/User/user/${phone}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user by phone:', error);
        throw error;
    }
};

export const getUserById = async (id) => {
    try {
        const response = await axios.get(`${baseUrl}/User/${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user:', error);
        throw error;
    }
};

export const getSuperAdminsOrAdmins = async (permissionId) => {
    try {
        const response = await axios.get(`${baseUrl}/User/superadminsoradmins/${permissionId}`);
        return response.data;
    } catch (error) {
        console.error("Error fetching admins:", error);
        throw error;
    }
};

export const getBodyOfLatestCommentMark = async (testId) => {
    try {
        const response = await axios.get(`${baseUrl}/Test/bodyOfCommentToMarkLatest`, {
            params: { TestId: testId }
        });
        return response.data; 
    } catch (error) {
        console.error("Error fetching the latest comment mark:", error);
        throw error; 
    }
};


export const addUser = async (adminPostModel) => { 
    try {
        const response = await axios.post(`${baseUrl}/User/`, adminPostModel);
        return response.data; 
    } catch (error) {
        console.error('Error adding user:', error);
        throw error; 
    }
};

export const updateAdmin = async (adminId, updatedAdmin) => {
    try {
        const response = await axios.put(`${baseUrl}/User/${adminId}`, updatedAdmin);
        return response.data;
    } catch (error) {
        console.error("Error updating admin:", error);
        throw new Error("Could not update admin");
    }
};


export const deleteUser = async (id) => {
    try {
        const response = await axios.delete(`${baseUrl}/User/${id}`);
        return response.data; 
    } catch (error) {
        console.error("Error deleting user:", error);
        throw error; 
    }
};

export const verifySmsCode = async (identifier, code) => {
    const response = await axios.post(`${baseUrl}/SendSms/verify-code`, {
        Identifier: identifier,
        Code: code
    });
    return response;
};

export const sendEmailVerificationCode = async (email) => {
    try {
        const response = await axios.post(`${baseUrl}/Email/request-email-code?Email=${encodeURIComponent(email)}`);
        return response;
    } catch (error) {
        console.error("Error sending email verification code:", error);
        throw error;
    }
};