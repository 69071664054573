
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTestToUser, getExamVersionsByPhone, loginUser, registerUser, sendEmailVerificationCode, verifySmsCode } from '../../Services/UserService';
import { useNavigate } from 'react-router-dom';
import { fetchSwitchValue, updateScores } from '../../Services/TestService';
import { useLocation } from 'react-router-dom';



const VerifySmsPopup = ({ fullName, phone, isEasylawStudent, setShowPopup, onError }) => {
    const [code, setCode] = useState(['', '', '', '']);
    const [email, setEmail] = useState('');
    const [showEmailInput, setShowEmailInput] = useState(false);
    const [showSmsInput, setShowSmsInput] = useState(false);
    const [isEmailVerification, setIsEmailVerification] = useState(false);
    const [phoneInput, setPhoneInput] = useState(phone);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const Alltests = useSelector(state => state.Test.tests);


    useEffect(() => {
        dispatch(fetchSwitchValue('Switch'));
    }, [dispatch]);

    const handleChange = (index, value) => {
        if (/^\d$/.test(value) || value === '') {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value !== '' && index < 3) {
                document.getElementById(`digit-${index + 1}`).focus();
            }

            if (newCode.every(digit => digit !== '')) {
                handleVerifyCode(newCode.join(''));
            }
        }
    };

    useEffect(() => {
        document.getElementById('digit-0').focus();
    }, [dispatch]);

    const isSwitchActive = useSelector(state => state.Test.isSwitchActive);

    // const handleFetchExamVersions = async () => {
    //     const response = await getExamVersionsByPhone(phoneInput);
    //     localStorage.setItem("proceduralVersion", response.proceduralVersion);
    //     localStorage.setItem("substantiveVersion", response.substantiveVersion);
    // };

    const resetVerificationState = () => {
        setCode(['', '', '', '']);
        if (isEmailVerification) {
            setShowEmailInput(true);
        } else {
            setShowSmsInput(true);
        }
        setShowPopup(false);
    };
    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
    };

    // const handleVerifyCode = async (verificationCode) => {
    //     try {
    //         const verificationResponse = isEmailVerification
    //             ? await verifySmsCode(email, verificationCode)
    //             : await verifySmsCode(phoneInput, verificationCode);

    //         const user = verificationResponse.data.user;

    //         if (user.permissionId === 1 || user.permissionId === 2) {
    //             await setCookie("user", JSON.stringify(user), 60 * 365); 
    //             await navigate('/admin/poolOfTest');
    //         } else {
    //             setCookie("user", JSON.stringify(user), 45); 
    //             await handleAddTestToUser(user);
    //         }
    //         setShowPopup(false);
    //         localStorage.setItem("token", verificationResponse.data.accessToken);
    //         localStorage.setItem("user", JSON.stringify(user));
    //     } catch (error) {
    //         alert("The code entered is invalid.");
    //         onError();
    //         if (location.pathname === 'register') {
    //             navigate('/register');
    //         } else if (location.pathname === 'login') {
    //             navigate('/login');
    //         }
    //     }
    // };
    const handleVerifyCode = async (verificationCode) => {
        try {
            const verificationResponse = isEmailVerification
                ? await verifySmsCode(email, verificationCode)
                : await verifySmsCode(phoneInput, verificationCode);

            const user = verificationResponse.data.user;
            console.log("user",user);
            console.log(user.permissionId)
            if (user.permissionId === 1 || user.permissionId === 2) {
                await setCookie("user", JSON.stringify(user), 60 * 365);
                await navigate('/admin/poolOfTest');
            } else {
                setCookie("user", JSON.stringify(user), 45);
                await handleAddTestToUser(user);
            }

            setShowPopup(false);
            localStorage.setItem("token", verificationResponse.data.accessToken);
            localStorage.setItem("user", JSON.stringify(user));
        } catch (error) {
            onError();
            if (location.pathname === 'register') {
                navigate('/register');
            } else if (location.pathname === 'login') {
                navigate('/login');
            }
        }
    };


    const handleCalculateScore = () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const latestTestId = Alltests.length > 0 ? Alltests[Alltests.length - 1].Id : null;
        updateScores({ UserId: user.Id, TestId: latestTestId });
    };

    // const handleSendEmailCode = async () => {
    //     try {
    //         const response = await sendEmailVerificationCode(email);

    //         if (response.status >= 200 && response.status < 300) {
    //             setIsEmailVerification(true);
    //             setShowEmailInput(false);
    //         } else {
    //             alert("Failed to send verification code to email.");
    //         }
    //     } catch (error) {
    //         console.error("Error sending email verification code:", error);
    //     }
    // };
    const handleSendEmailCode = async () => {
        try {
            const userData = {
                Name: fullName,
                Phone: phone,
                Email: email,
                IsOurStudent: isEasylawStudent,
                ConnectedAt: new Date(),
            };
            if (location.pathname === '/register') {
                dispatch(registerUser(userData, true)).then((response) => {
                    if (response?.message === "Verification code sent to your email.") {
                        setIsEmailVerification(true);
                        setShowEmailInput(false); // סגירת שדה הכנסת האימייל
                    } else {
                        navigate('/login');
                    }
                });
            } else if (location.pathname === '/login') {
                const response = await loginUser(email)
                if (response?.message == "Verification code sent successfully.") {
                    setIsEmailVerification(true);
                    setShowEmailInput(false);
                }
                else {
                    if (response?.message == "You are not registered. Please register first.") {
                        navigate('/register');
                    } 
                }

            };
        
        }catch(error){
            console.error("Error sending email verification code:", error);
        }
    };

            const handleSendSmsCode = async () => {
                setPhoneInput(phoneInput);
                await handleSubmit();
            };
            const handleAddTestToUser = async (user) => {
                const userId = user.id;
                const versionOfDiscussion = parseInt(localStorage.getItem('versionOfDiscussion'), 10);
                const versionOfSubstantive = parseInt(localStorage.getItem('versionOfSubstantive'), 10);
                const discussionQuestions = JSON.parse(localStorage.getItem('discussionQuestions') || '[]').map(Number);
                const substantiveQuestions = JSON.parse(localStorage.getItem('substantiveQuestions') || '[]').map(Number);
                const response = await dispatch(addTestToUser(userId, versionOfDiscussion, versionOfSubstantive, discussionQuestions, substantiveQuestions));

                if (window.location.pathname === '/login') {
                    if (isSwitchActive) {
                        // await handleFetchExamVersions();
                        navigate('/user/test/mark');
                    } else {
                        navigate('/user/test/finish');
                    }
                }

                if (response.message === "User successfully added to the test with answers.") {
                    if (window.location.pathname === '/register') {
                        if (isSwitchActive) {
                            // await handleFetchExamVersions();
                            // await handleCalculateScore();
                            navigate('/user/test/mark');
                        } else {
                            navigate('/user/test/finish');
                            console.log('Test successfully added');
                        }
                    }
                }
            };

            const handleSubmit = async () => {
                const response = await loginUser(phone)
                if (response?.message == "Verification code sent successfully.") {
                    setIsEmailVerification(false);
                    setShowSmsInput(false);
                }
                else {
                    if ((response?.message == "You are not registered. Please register first.") ||
                        (response?.message == "Your registration has expired. Please register again.")) {
                        navigate('/register');
                    } else {
                        alert('ההתחברות נכשלה');
                    }
                }

            };

            return (

                <div className="Sms-v-s-p" style={{ direction: 'rtl' }}>
                    <div className="Title-v-s-p">
                        <div className="TitleText-v-s-p">התחברות</div>
                    </div>

                    {!showEmailInput && !showSmsInput ? (
                        <>
                            <div className="Title-v-s-p">
                                <div className="SmsMessage-v-s-p">
                                    {!isEmailVerification ? (
                                        <>
                                            <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת sms עם קוד אימות,</div>
                                            <br />
                                            <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='txt-v-s-p'>ברגע זה נשלחה אליך הודעת מייל עם קוד אימות,</div>
                                            <br />
                                            <div className='txt-v-s-p'>נא להקליד את הקוד שהתקבל</div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="VerificationCode-v-s-p">
                                {code.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`digit-${index}`}
                                        className="VerificationInput-v-s-p"
                                        type="text"
                                        maxLength="1"
                                        value={digit}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onFocus={(e) => e.target.select()}
                                    />
                                ))}
                            </div>

                            <div className="switch-verification-method">
                                {!isEmailVerification ? (
                                    <a className='switch-to-email' href="#" onClick={() => setShowEmailInput(true)}>לקבלת קוד באימייל</a>
                                ) : (
                                    <a className='switch-to-sms' href="#" onClick={() => setShowSmsInput(true)}>לקבלת קוד ב-sms</a>
                                )}
                            </div>
                        </>
                    ) : showEmailInput ? (
                        <>
                            <div className="Title-v-s-p">
                                <div className="SmsMessage-v-s-p">
                                    <div className='txt-v-s-p'>כתובת המייל איתה נרשמת:</div>
                                </div>
                            </div>
                            <input
                                type="email"
                                placeholder="הכנס כתובת מייל"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="Input-Email-vsp"
                            />
                            <button className='send-email-btn' onClick={handleSendEmailCode}>שליחה</button>
                        </>
                    ) : (
                        <>
                            <div className="Title-v-s-p">
                                <div className="SmsMessage-v-s-p">
                                    <div className='txt-v-s-p'>לאיזה טלפון ישלח הקוד:</div>
                                </div>
                            </div>
                            <input
                                type="tel"
                                placeholder="הכנס מספר טלפון"
                                value={phoneInput}
                                onChange={(e) => setPhoneInput(e.target.value)}
                                className="Input-Email-vsp"
                            />
                            <button className='send-email-btn' onClick={handleSendSmsCode}>שליחה</button>
                        </>
                    )}
                </div>
            );
        };

        export default VerifySmsPopup;






