import React, { useState } from 'react';
import '../../style/Login.css';
import { useNavigate } from 'react-router-dom';
import VerifySmsPopup from './VerifySmsPopup';
import { loginUser } from '../../Services/UserService';




const LoginToSystem = () => {
  const [phone, setPhone] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const logoImage = require('../../assets/easylaw-logo-01.jpg');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser(phone)

    if (response?.message == "Verification code sent successfully.") {
      setShowPopup(true);
    }
    else {
      if (response?.message == "You are not registered. Please register first.") {
        navigate('/register');
      } else {
        alert('ההתחברות נכשלה');
      }
    }

  };

  const handleVerificationFailure = () => {
    setShowPopup(false);
  };
  // useEffect(() => {
  //   dispatch(fetchAllUsers());
  // }, [dispatch]);

  const handleVerifySuccess = () => {
    navigate('/user/test/finish');
  };


  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   console.log('Phone number submitted:', phone);
  // };

  return (
    <div className="login-container-login">
      <div className="frame21-login">
        <img className="easylaw-logo-login" src={logoImage} alt="EasyLaw Logo" />
      </div>
      <div className="frame1707478209-login">
        <div className="frame1707478208-login">
          <div className="title-login">
            <div className="title-text-login">התחברות</div>
          </div>
          <form onSubmit={handleSubmit} className="frame19-login">
            <div className="text-field-caption-login">
              <div className="frame21684-login">
                <label htmlFor="phone" className="label-login">טלפון</label>
              </div>
              <div className="text-field-phone-login">
                <input
                  className="clone-login"
                  type="tel"
                  id="phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  // placeholder="כדי שנוכל לשלוח לך לינק ב-sms לצפיה בציון המשוער"
                  required
                />
              </div>

            </div>
            <div className="button-container-login">
              <button type='submit' onClick={handleSubmit} className="submit-button-login">אישור</button>
            </div>


          </form>
        </div>
      </div>
      {showPopup && (
        <div className='showPopupVerify'>
          <VerifySmsPopup phone={phone} setShowPopup={setShowPopup} onError={handleVerificationFailure} />
        </div>
      )}
    </div>
  );
};

export default LoginToSystem;


// import React, { useState } from 'react';
// import '../../style/Login.css';
// import { useNavigate } from 'react-router-dom';
// import VerifySmsPopup from './VerifySmsPopup';
// import { loginUser } from '../../Services/UserService';

// const LoginToSystem = () => {
//   const [phone, setPhone] = useState('');
//   const [email, setEmail] = useState('');
//   const [isEmailMode, setIsEmailMode] = useState(false); // To toggle between phone and email mode
//   const [showPopup, setShowPopup] = useState(false);
//   const logoImage = require('../../assets/easylaw-logo-01.jpg');
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     const response = isEmailMode ? await loginUser(email, 'email') : await loginUser(phone);

//     if (response?.message === "Verification code sent successfully.") {
//       setShowPopup(true);
//     } else {
//       if (
//         response?.message === "You are not registered. Please register first." ||
//         response?.message === "Your registration has expired. Please register again."
//       ) {
//         navigate('/register');
//       } else {
//         alert('ההתחברות נכשלה');
//       }
//     }
//   };

//   const handleVerificationFailure = () => {
//     setShowPopup(false);
//   };

//   const handleVerifySuccess = () => {
//     navigate('/user/test/finish');
//   };

//   return (
//     <div className="login-container-login">
//       <div className="frame21-login">
//         <img className="easylaw-logo-login" src={logoImage} alt="EasyLaw Logo" />
//       </div>
//       <div className="frame1707478209-login">
//         <div className="frame1707478208-login">
//           <div className="title-login">
//             <div className="title-text-login">התחברות</div>
//           </div>
//           <form onSubmit={handleSubmit} className="frame19-login">
//             <div className="text-field-caption-login">
//               <div className="frame21684-login">
//                 <label htmlFor={isEmailMode ? 'email' : 'phone'} className="label-login">
//                   {isEmailMode ? 'מייל' : 'טלפון'}
//                 </label>
//               </div>
//               <div className="text-field-phone-login">
//                 <input
//                   className="clone-login"
//                   type={isEmailMode ? 'email' : 'tel'}
//                   id={isEmailMode ? 'email' : 'phone'}
//                   value={isEmailMode ? email : phone}
//                   onChange={(e) => (isEmailMode ? setEmail(e.target.value) : setPhone(e.target.value))}
//                   required
//                 />
//               </div>
//             </div>
//             <div className="button-container-login">
//               <button type='submit' className="submit-button-login">אישור</button>
//             </div>
//           </form>
//           <div className="switch-to-email-link">
//             <a href="#" onClick={() => setIsEmailMode(!isEmailMode)}>
//               {isEmailMode ? ' לחץ כאן sms לקבלת קוד בהודעת ' : 'לקבלת קוד במייל לחץ כאן'}
//             </a>
//           </div>
//         </div>
//       </div>
//       {showPopup && (
//         <div className='showPopupVerify'>
//           <VerifySmsPopup
//             phone={phone}
//             email={email}
//             setShowPopup={setShowPopup}
//             onError={handleVerificationFailure}
//             isEmailMode={isEmailMode}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default LoginToSystem;
