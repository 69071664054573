import React from 'react';
import '../../../style/PopupDeleteTest.css'; 
import { Dialog } from '@mui/material';

const PopupLogout = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} style={{ backgroundColor: "rgba(255, 255, 255, 0.1)"}}>
      <div className="PopupError-p-d-t">
        <div className="Frame1707478455-p-d-t">
          <div className="Frame14-p-d-t">
            <div className="Title2-p-d-t">אישור התנתקות</div>
          </div>
          <div className="Title-p-d-t">
            <div className="Message-p-d-t">
            בלחיצה על אישור יתבצע ניתוק מן המערכת
            ועליך יהיה להתחבר מחדש
            </div>
            <div className="ConfirmationQuestion-p-d-t">
              האם הנך מוכן לכך?
            </div>
          </div>
        </div>
        <div className="Frame22-p-d-t">
          <div className="Button-p-d-t" onClick={onConfirm}>
            <div className="ButtonMaster-p-d-t">
              <div className="ButtonConfirm-p-d-t">
                <div className="ButtonText-p-d-t">אישור</div>
              </div>
            </div>
          </div>
          <div className="Button-p-d-t" onClick={onClose}>
            <div className="ButtonMaster-p-d-t">
              <div className="ButtonCancel-p-d-t">
                <div className="ButtonText-p-d-t">ביטול</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PopupLogout;
