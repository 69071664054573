
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SignIn from './components/user/SignInForm';
import LoginToSystem from './components/user/LoginToSystem';
import LandingPage from './pages/landingPage';
import SolutionTestComponent from './components/examinees/solutionsTest';
import FinishWindow from './components/examinees/finishWindow';
import RecieveMark from './components/examinees/RecieveMark';
import TopBar from './components/Admin/topBar';
import TestPool from './components/Admin/testPool';
import ScoresTable from './components/Admin/scoresTable';
import SettingIdenticalValues from './components/Admin/settingIdenticalValues';
import StatisticsTable from './components/Admin/statisticTable';
import CommentsOfQuestion from './components/Admin/commentsOfQuestion';
import { useEffect, useState } from 'react';
import SideBar from './components/Admin/SideBar';
import AllAdminCards from './components/Admin/PersonalArea/allAdminCards';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSwitchValue } from './Services/TestService';
import LogoutIcon from '@mui/icons-material/Logout';
import PopupLogout from './components/Admin/popups/popupLogOut';



function App() {
  const location = useLocation(); // משמש לקבלת ה-URL הנוכחי
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const navigate = useNavigate();
  // הגדרת הנתיבים שבהם נרצה להציג את ה-SideBar
  const pathsWithSidebar = [
    '/admin/statistic',
    '/admin/scores',
    '/setting'
  ];



  


  const userString = localStorage.getItem('user');
  let userObject = null;

  if (userString) {
    userObject = JSON.parse(userString);
    console.log(userObject, "userObject");
  }

  // useEffect(() => {
  //   if (location.pathname === '/login') {
  //     localStorage.clear(); 
  //   }

  //   const handleUnload = (event) => {
  //     if (!event.persisted) {
  //     }
  //   };

  //   window.addEventListener('unload', handleUnload);
  //   return () => {
  //     window.removeEventListener('unload', handleUnload);
  //   };
  // }, [location.pathname]); 

  return (
    <>
      {/* הצגת ה-TopBar רק אם המשתמש לא נמצא בדף ההתחברות */}
      {location.pathname !== '/login' && location.pathname !== '/' && location.pathname !== '/register' && location.pathname !== '/user/test' && location.pathname !== '/user/test/finish' && location.pathname !== '/user/test/mark' && <TopBar />}

      {/* הצגת ה-SideBar רק אם ה-URL תואם לאחד מהנתיבים שהוגדרו */}
      {pathsWithSidebar.includes(location.pathname) && (
        <div className="sidebar-container">
          <SideBar />
        </div>
      )}


      <Routes>
        {/* for everyOne */}
        <Route path="/register" element={<SignIn />} />
        <Route path="/login" element={<LoginToSystem />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/user/test" element={<SolutionTestComponent />} />
        <Route path="/user/test/finish" element={<FinishWindow />} />
        <Route path="/user/test/mark" element={<RecieveMark />} />

        {/* for admins */}
        <Route path="/admin/poolOfTest" element={<TestPool />} />
        <Route path="/admin/scores" element={<ScoresTable />} />
        <Route path="/setting" element={<SettingIdenticalValues />} />
        <Route path="/admin/statistic" element={<StatisticsTable />} />
        <Route path="/admin/statistic/comments" element={<CommentsOfQuestion />} />
        <Route path="/all-admin" element={<AllAdminCards />} />
      </Routes>
    </>
  );
}

export default App;
