// import { useEffect, useState } from 'react';
// import '../../style/ScoresTable.css';
// import { getAverageScores } from '../../Services/TestService';

// // ייבוא של תמונות
// const pasport = require('../../assets/Passport.jpg');
// const notebook_b = require('../../assets/Notebook Bookmark.jpg');
// const notebook = require('../../assets/Notebook.jpg');

// const AvgScores = ({ testId ,rows}) => {
//     const [scores, setScores] = useState({
//         AverageProceduralScore: 0,
//         AverageSubstantiveScore: 0,
//         AverageFinalScore: 0,
//         AverageWritingTaskScore: 0
//     });

//     useEffect(() => {
//         const fetchData = async () => {
//             try {
//                 const data = await getAverageScores(testId);
//                 if (data) {
//                     console.log(data);  // הדפסת המידע בקונסול לצורכי debug
//                     setScores(data);  // עדכון הסטייט
//                 }
//             } catch (error) {
//                console.log('Failed to fetch average scores');
//             }
//         };
//         fetchData();
//     },[testId,rows]);

//     return (
//         <div className="Section-s-t">
//             <div className="Container-s-t">
//                 <div className="MetricGroup-s-t">
//                     {renderCard(scores.averageFinalScore, 'ציון סופי', pasport, '#013B82')}
//                     {renderCard(scores.averageWritingTaskScore, 'מטלת הכתיבה', notebook, '#DD9F6B')}
//                     {renderCard(scores.averageSubstantiveScore, 'ציון דין מהותי', notebook_b, '#17C2D0')}
//                     {renderCard(scores.averageProceduralScore, 'ציון דין דיוני', notebook, '#DD9F6B')}
//                 </div>
//             </div>
//         </div>
//     );
// };

// const renderCard = (number, heading, iconSrc, borderColor) => {
//     return (
//         <div className="GradePointAverage-s-t">
//             <div className="Number-s-t">{number}</div>
//             <div className="Frame1707478281-s-t">
//                 <img src={iconSrc} alt={heading} className="Icon-s-t" style={{ borderColor }} />
//                 <div className="Heading-s-t">{heading}</div>
//             </div>
//         </div>
//     );
// };

// export default AvgScores;


import React, { useEffect, useState } from 'react';
import '../../style/ScoresTable.css';
import { getAverageScores } from '../../Services/TestService';
const passport = require('../../assets/Passport.jpg');
const notebook_b = require('../../assets/Notebook Bookmark.jpg');
const notebook = require('../../assets/Notebook.jpg');

const AvgScores = ({ testId, rows }) => {
    const [scores, setScores] = useState({
        averageFinalScore: 0,
        averageWritingTaskScore: 0,
        averageSubstantiveScore: 0,
        averageProceduralScore: 0,
    });

    const [displayedScores, setDisplayedScores] = useState({
        averageFinalScore: 0,
        averageWritingTaskScore: 0,
        averageSubstantiveScore: 0,
        averageProceduralScore: 0,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getAverageScores(testId);
                if (data) {
                    console.log(data); 
                    setScores({
                        averageFinalScore: data.averageFinalScore,
                        averageWritingTaskScore: data.averageWritingTaskScore,
                        averageSubstantiveScore: data.averageSubstantiveScore,
                        averageProceduralScore: data.averageProceduralScore,
                    });
                }
            } catch (error) {
                console.log('Failed to fetch average scores');
            }
        };
        fetchData();
    }, [testId, rows]);

    useEffect(() => {
        const animateScore = (scoreKey) => {
            let currentScore = 0;
            const increment = Math.ceil(scores[scoreKey] / 50);
            const interval = setInterval(() => {
                if (currentScore >= scores[scoreKey]) {
                    clearInterval(interval);
                    setDisplayedScores((prevScores) => ({
                        ...prevScores,
                        [scoreKey]: scores[scoreKey],
                    }));
                } else {
                    currentScore += increment;
                    setDisplayedScores((prevScores) => ({
                        ...prevScores,
                        [scoreKey]: currentScore,
                    }));
                }
            }, 20); 
        };

        if (scores.averageFinalScore !== 0) {
            animateScore('averageFinalScore');
        }
        if (scores.averageWritingTaskScore !== 0) {
            animateScore('averageWritingTaskScore');
        }
        if (scores.averageSubstantiveScore !== 0) {
            animateScore('averageSubstantiveScore');
        }
        if (scores.averageProceduralScore !== 0) {
            animateScore('averageProceduralScore');
        }
    }, [scores]);

    return (
        <div className="Section-s-t">
            <div className="Container-s-t">
                <div className="MetricGroup-s-t">
                    {renderCard(displayedScores.averageFinalScore, 'ציון סופי', passport, '#013B82')}
                    {renderCard(displayedScores.averageWritingTaskScore, 'מטלת הכתיבה', notebook, '#DD9F6B')}
                    {renderCard(displayedScores.averageSubstantiveScore, 'ציון דין מהותי', notebook_b, '#17C2D0')}
                    {renderCard(displayedScores.averageProceduralScore, 'ציון דין דיוני', notebook, '#DD9F6B')}
                </div>
            </div>
        </div>
    );
};

const renderCard = (number, heading, iconSrc, borderColor) => {
    return (
        <div className="GradePointAverage-s-t">
            <div className="Number-s-t">{number}</div>
            <div className="Frame1707478281-s-t">
                <img src={iconSrc} alt={heading} className="Icon-s-t" style={{ borderColor }} />
                <div className="Heading-s-t">{heading}</div>
            </div>
        </div>
    );
};

export default AvgScores;
