
import React, { useState, useEffect } from 'react';
import '../../../style/Allpopup.css';
import { ReactComponent as LetterIcon } from '../../../assets/letter.svg';
import { ReactComponent as HideCopy } from '../../../assets/eye-less.svg';
import { ReactComponent as CopyIcon } from '../../../assets/copy.svg';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { fetchAllMessages, fetchPhoneNumbers, fetchPhoneNumbersByScoreRange, sendSms } from '../../../Services/UserService';
import { saveMessage } from '../../../Services/TestService';

const PopupSendMessage = ({ open, onClose, testId }) => {

  const sendIcon_blue = require('../../../assets/send-blue.jpg');
  const msgIcon_blue = require('../../../assets/msg-blue.jpg');
  const [messageTitle, setMessageTitle] = useState('');
  const [selectedTarget, setSelectedTarget] = useState('students');
  const [minScore, setMinScore] = useState('');
  const [maxScore, setMaxScore] = useState('');
  const [showSavedMessages, setShowSavedMessages] = useState(false);
  const [messageBody, setMessageBody] = useState('');
  const [selectedMessageIndex, setSelectedMessageIndex] = useState(null);
  const [savedMessages, setSavedMessages] = useState([]); 
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [Isstudent, setIsStudents] = useState(true);

  useEffect(() => {
    if (showSavedMessages) {
      fetchAllMessages()
        .then(response => {
          setSavedMessages(response);
          console.log('Fetched messages:', response);
        })
        .catch(error => {
          console.error('There was an error fetching the saved messages!', error);
        });
    }
  }, [showSavedMessages]);

  useEffect(() => {
    if (!open) return;
    if (selectedTarget === 'score-range' && minScore && maxScore) {
        fetchPhoneNumbersByScoreRange(testId, minScore, maxScore)
            .then(data => {
                setPhoneNumbers(data);
                console.log("Fetched phone numbers by score range:", data);
            })
            .catch(error => console.error('Error fetching phone numbers by score range:', error));
    } else {
        fetchPhoneNumbers(testId, Isstudent)
            .then(data => {
                setPhoneNumbers(data);
                console.log("Fetched phone numbers :", data);
            })
            .catch(error => console.error('Error fetching phone numbers:', error));
    }
}, [selectedTarget, minScore, maxScore, testId, Isstudent, open]);

  const handleTitleChange = (e) => {
    setMessageTitle(e.target.value);
  };

  const handleRadioChange =async (e) => {
    const target = e.target.value;
    await setSelectedTarget(target);
    await setIsStudents(target === 'students');
    console.log("Updated selectedTarget:", target);
    console.log("Updated Isstudent:", target === 'students');
    
  };

  const handleMinScoreChange = (e) => {
    setMinScore(e.target.value);
  };

  const handleMaxScoreChange = (e) => {
    setMaxScore(e.target.value);
  };

  const handleShowSavedMessages = () => {
    setShowSavedMessages(!showSavedMessages);
  };

  const handleBodyChange = (e) => {
    setMessageBody(e.target.value);
  };

  const handleSendMessage = async () => {
    const footerText = '\n\nלהסרה: https://b.s4f.im/306'; 
    for (const phoneNumber of phoneNumbers) {
      console.log(phoneNumber);
      const smsData = {
        Title: messageTitle,
        To: phoneNumber,
        Message: messageBody + footerText
      };

      try {
        await sendSms(smsData);
      } catch (error) {
        alert('Failed to send the message to ', phoneNumber);
      }
    }
    onClose();
  };

  const handleSaveMessage = async () => {
    try {
      await saveMessage(messageTitle, messageBody);
      fetchAllMessages().then(response => {
        setSavedMessages(response);
        alert('Message saved successfully');
      });
    } catch (error) {
      alert('Failed to save the message');
    }
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(dateString).toLocaleDateString('he-IL', options);
  };

  const isSendButtonDisabled = !(messageTitle && selectedTarget && messageBody);

  if (!open) return null;

  return (
    <div className="overlay-s-m" onClick={onClose}>
      <div className="popup-s-m" onClick={(e) => e.stopPropagation()}>
        <div className="header-s-m">
          <h2 className="subtitle-s-m">שליחת הודעה</h2>
          <input
            type="text"
            className="input-title-s-m"
            placeholder="נושא ההודעה"
            value={messageTitle}
            onChange={handleTitleChange}
          />
        </div>
        <div className="section-s-m">
          <h1 className="title-s-m"><img src={sendIcon_blue} alt="Send Icon" className="icon-s-m" />מען לשליחה</h1>
          <div className="radioGroup-s-m">
            <RadioGroup value={selectedTarget} onChange={handleRadioChange}>
              <FormControlLabel
                value="students"
                control={<Radio className="custom-radio" />}
                label="תלמידי Easylaw"
                className={`radioLabel-s-m ${selectedTarget === 'students' ? 'selected' : ''}`}
              />
              <FormControlLabel
                value="non-students"
                control={<Radio className="custom-radio" />}
                label="לכולם מלבד תלמידי Easylaw"
                className={`radioLabel-s-m ${selectedTarget === 'non-students' ? 'selected' : ''}`}
              />
              <FormControlLabel
                value="score-range"
                control={<Radio className="custom-radio" />}
                label="לפי דרגת ציון:"
                className={`radioLabel-s-m ${selectedTarget === 'score-range' ? 'selected' : ''}`}
              />
            </RadioGroup>
          </div>

          {selectedTarget === 'score-range' && (
            <div className="score-range-s-m">
              <div className="Text">כל מי שקיבל ציון בין</div>
              <input
                type="number"
                className="scoreInput-s-m"
                placeholder="מינימום"
                value={minScore}
                onChange={handleMinScoreChange}
              />
              <div className="Text">לבין</div>
              <input
                type="number"
                className="scoreInput-s-m"
                placeholder="מקסימום"
                value={maxScore}
                onChange={handleMaxScoreChange}
              />
            </div>
          )}
        </div>
        <div className="section-s-m">
          <h1 className="title-s-m"><img src={msgIcon_blue} alt="Message Icon" className="icon-s-m" />כתיבת הודעה</h1>
          <textarea className="textField-s-m" placeholder="הכנס תוכן הודעה" value={messageBody} onChange={handleBodyChange} />
          <h2 className="showSavedMessagesButton-s-m" onClick={handleShowSavedMessages}>
            {showSavedMessages ? (
              <>
                <HideCopy /> הסתרת הודעות שמורות
              </>
            ) : (
              <>
                הצגת הודעות שמורות
              </>
            )}
          </h2>
          {showSavedMessages && (
            <div className="savedMessages-s-m">
              <div className="savedMessagesHeader-s-m">נשמרו לאחרונה</div>
              {savedMessages.map((msg, index) => (
                <div
                  className={`savedMessageItem-s-m ${selectedMessageIndex === index ? 'selected' : ''}`}
                  key={index}
                  onClick={() => setSelectedMessageIndex(index)}
                >
                  <LetterIcon className="savedMessageIcon-s-m" />
                  <div className="messageTitle-s-m">{msg.title}</div>
                  <div className="messageDate-s-m">{formatDate(msg.sendingDate)}</div>
                  {selectedMessageIndex === index && (
                    <div className="copyIcon-s-m" onClick={() => {
                      setMessageBody(msg.bodyMessage);
                      setMessageTitle(msg.title);
                    }}>
                      <CopyIcon />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="buttonGroup-s-m">
          <button className="button-s-m saveButton-s-m" onClick={handleSaveMessage}>שמירת הודעה</button>
          <button
            className={`button-s-m sendButton-s-m ${isSendButtonDisabled ? 'disabled' : 'enabled'}`}
            onClick={isSendButtonDisabled ? undefined : handleSendMessage}
            disabled={isSendButtonDisabled}
          >
            שליחה
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupSendMessage;
