import React, { useState } from 'react';
import '../../style/SignIn.css'; 
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../Services/UserService';
import VerifySmsPopup from './VerifySmsPopup';

const SignInForm = () => {
  const [fullName, setFullName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [isEasylawStudent, setIsEasylawStudent] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validateForm = () => {
    const newErrors = {};
    if (!fullName || fullName.trim().split(' ').length < 2) {
      newErrors.fullName = 'יש להזין שם מלא עם שם ושם משפחה.';
    }
    if (!phone || !/^\d{10}$/.test(phone)) {
      newErrors.phone = 'יש להזין מספר טלפון המכיל בדיוק 10 ספרות.';
    }
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'יש להזין כתובת מייל תקינה.';
    }
    if (!termsAccepted) {
      newErrors.termsAccepted = 'עליך לאשר את תנאי השימוש.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const userData = {
        Name: fullName,
        Phone: phone,
        Email: email,
        IsOurStudent: isEasylawStudent,
        ConnectedAt: new Date(),
    };

    dispatch(registerUser(userData, false)).then((response) => {
        if (response.success) {
            if (response.message === "Verification code sent to your phone.") {
                setShowPopup(true);
            } else {
                navigate("/login");
            }
        } else {
            alert(response.message); // הצגת הודעת שגיאה במידת הצורך
        }
    });
};

  const logoImage = require('../../assets/easylaw-logo-01.jpg');

  const handleVerifySuccess = () => {
    navigate('/user/test/finish');
  };

  const handleVerificationFailure = () => {
    setShowPopup(false); 
  };

  return (
    <div className="container-sign">
      <div className="logo-sign">
        <img className="logo-img-sign" src={logoImage} alt="Logo" />
      </div>
      <div className="form-container-sign">
        <div className="form-title-sign">הרשמה</div>
        <div className="form-content-sign">
          <div className={`form-field-sign ${errors.fullName ? 'error' : ''}`}>
            <div className="form-label-sign">שם מלא</div>
            <div className="text-field-sign">
              <input
                className="text-input-sign"
                type="text"
                placeholder="שם מלא"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
              />
            </div>
            {errors.fullName && <span className="error-message">{errors.fullName}</span>}
          </div>
          <div className={`form-field-sign ${errors.phone ? 'error' : ''}`}>
            <div className="form-label-sign">טלפון</div>
            <div className="text-field-sign">
              <input
                className="text-input-sign"
                type="text"
                placeholder="כדי שנוכל לשלוח לך לינק ב-SMS לצפייה בציון המשוער"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            {errors.phone && <span className="error-message">{errors.phone}</span>}
          </div>
          <div className={`form-field-sign ${errors.email ? 'error' : ''}`}>
            <div className="form-label-sign">כתובת מייל</div>
            <div className="text-field-sign">
              <input
                className="text-input-sign"
                type="email"
                placeholder="כדי שנוכל לשלוח לך עדכונים חשובים"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {errors.email && <span className="error-message">{errors.email}</span>}
          </div>
          <div className="form-field-sign">
            <label className="switch-label-sign">
              <input
                type="checkbox"
                className={`switch-input-sign ${isEasylawStudent ? 'active' : ''}`}
                checked={isEasylawStudent}
                onChange={(e) => setIsEasylawStudent(e.target.checked)}
              />
              <span className={`switch-slider-sign ${isEasylawStudent ? 'active' : ''}`}></span>
              למדתי ב-EASYLAW
            </label>
          </div>
          <div className={`form-field-sign ${errors.termsAccepted ? 'error' : ''}`}>
            <label className="terms-label-sign">
              <input
                type="checkbox"
                className="terms-input-sign"
                checked={termsAccepted}
                onChange={(e) => setTermsAccepted(e.target.checked)}
              />
              קראתי ואישרתי את
              <span
                className="terms-link-sign"
                onClick={() => window.location.href = "https://hi.easylaw.io/grade-policy"}
              >
                תנאי השימוש
              </span>
            </label>
            {errors.termsAccepted && <span className="error-message">{errors.termsAccepted}</span>}
          </div>
          <button className="submit-button-sign" onClick={handleSignUp}>
            שליחה
          </button>
        </div>
      </div>
      <div>
        <div className="login-link-container">
          <span className='spn-sign'>כבר הזנת תשובות?</span>
          <span className="login-link" onClick={() => { navigate('/login') }}>התחברות</span>
        </div>
      </div>
      {showPopup && (
        <div className='showPopupVerify-login'>
          <VerifySmsPopup fullName={fullName} phone={phone} isEasylawStudent={isEasylawStudent} onSuccess={handleVerifySuccess} setShowPopup={setShowPopup} onError={handleVerificationFailure}/>
        </div>
      )}
    </div>
  );
};

export default SignInForm;
