
import React, { useEffect, useState } from 'react';
import '../../style/Settings.css';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody
} from '@mui/material';
import { getQuestionIndexes, updateQuestionIndexes } from '../../Services/TestService';

const SettingIdenticalValues = () => {
    const testDisplay = localStorage.getItem('test');
    const testDetails = JSON.parse(testDisplay);
    const [errorCells, setErrorCells] = useState(Array.from({ length: 40 }, () => Array(5).fill(false)));
    const [currentLawType, setCurrentLawType] = useState('דין דיוני');
    const [selectedRow, setSelectedRow] = useState(null);
    const [versionValues, setVersionValues] = useState({
        'גירסה 1': Array.from({ length: 40 }, (_, i) => `${i + 1}`),
        'תחילת השאלה': Array.from({ length: 40 }, () => ''),
        'גירסה 2': Array.from({ length: 40 }, () => ''),
        'גירסה 3': Array.from({ length: 40 }, () => ''),
        'גירסה 4': Array.from({ length: 40 }, () => '')
    });

    // useEffect(() => {
    //     const fetchData = async (lawType) => {
    //         try {
    //             const typeId = lawType === 'דין דיוני' ? 1 : 2;
    //             const data = await getQuestionIndexes(testDetails.testId, typeId);

    //             if (data && data.length > 0) {
    //                 setVersionValues({
    //                     'גירסה 1': data.map(question => question.version1Index || ''),
    //                     'תחילת השאלה': data.map(question => question.beginningText || ''),
    //                     'גירסה 2': data.map(question => question.version2Index || ''),
    //                     'גירסה 3': data.map(question => question.version3Index || ''),
    //                     'גירסה 4': data.map(question => question.version4Index || '')
    //                 });
    //             }
    //         } catch (error) {
    //             console.error('Failed to fetch data:', error);
    //         }
    //     };

    //     fetchData(currentLawType);
    // }, [currentLawType, testDetails.testId]);

    useEffect(() => {
        const fetchData = async (lawType) => {
            try {
                const typeId = lawType === 'דין דיוני' ? 1 : 2;
                const data = await getQuestionIndexes(testDetails.testId, typeId);
    
                if (data && data.length > 0) {
                    setVersionValues({
                        'גירסה 1': data.map(question => question.version1Index || ''),
                        'תחילת השאלה': data.map(question => question.beginningText || ''),
                        'גירסה 2': data.map(question => question.version2Index || ''),
                        'גירסה 3': data.map(question => question.version3Index || ''),
                        'גירסה 4': data.map(question => question.version4Index || '')
                    });
                } else {
                    // אם אין נתונים, לאתחל ערכים ריקים
                    setVersionValues({
                        'גירסה 1': Array.from({ length: 40 }, (_, i) => `${i + 1}`),
                        'תחילת השאלה': Array.from({ length: 40 }, () => ''),
                        'גירסה 2': Array.from({ length: 40 }, () => ''),
                        'גירסה 3': Array.from({ length: 40 }, () => ''),
                        'גירסה 4': Array.from({ length: 40 }, () => '')
                    });
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
    
        // לאתחל את הנתונים עם החלפת סוג הדין
        setVersionValues({
            'גירסה 1': Array.from({ length: 40 }, (_, i) => `${i + 1}`),
            'תחילת השאלה': Array.from({ length: 40 }, () => ''),
            'גירסה 2': Array.from({ length: 40 }, () => ''),
            'גירסה 3': Array.from({ length: 40 }, () => ''),
            'גירסה 4': Array.from({ length: 40 }, () => '')
        });
    
        fetchData(currentLawType);
    }, [currentLawType, testDetails.testId]);
    

    const handleValueChange = (version, index, newValue) => {
        setVersionValues(prevState => {
            const newState = {
                ...prevState,
                [version]: prevState[version].map((value, i) => (i === index ? newValue : value))
            };
            return newState;
        });
    };

    const handleUpdateClick = async () => {
        const versionsToCheck = ['גירסה 2', 'גירסה 3', 'גירסה 4'];
        const invalidColumns = [];
        let duplicateNumbers = {};

        versionsToCheck.forEach((version) => {
            const columnValues = versionValues[version].map(Number).filter(Boolean);
            const sum = columnValues.reduce((acc, val) => acc + val, 0);

            if (sum !== 820) {
                invalidColumns.push(version);
                const countMap = {};
                duplicateNumbers[version] = columnValues.filter(num => {
                    countMap[num] = (countMap[num] || 0) + 1;
                    return countMap[num] > 1;
                });
            }
        });

        if (invalidColumns.length > 0) {
            alert(`לא ניתן לעדכן: נמצאו עמודות עם ערכים כפולים:\n${invalidColumns.map(column => `${column}: ${duplicateNumbers[column].join(', ')}`).join('\n')}`);
            return;
        }

        const typeId = currentLawType === 'דין דיוני' ? 1 : 2;
        const requestData = {
            testId: testDetails.testId,
            typeOfExamId: typeId,
            questions: Array.from({ length: 40 }, (_, i) => ({
                version1Index: parseInt(versionValues['גירסה 1'][i], 10) || 0,
                version2Index: parseInt(versionValues['גירסה 2'][i], 10) || 0,
                version3Index: parseInt(versionValues['גירסה 3'][i], 10) || 0,
                version4Index: parseInt(versionValues['גירסה 4'][i], 10) || 0,
                beginningText: versionValues['תחילת השאלה'][i]
            }))
        };

        try {
            await updateQuestionIndexes(requestData);
            alert('העדכון בוצע בהצלחה');
        } catch (error) {
            alert('שגיאה בעדכון');
        }
    };

    const validateVersionValue = (value) => {
        const numValue = parseInt(value, 10);
        return !isNaN(numValue) && numValue >= 1 && numValue <= 40;
    };

    return (
        <div className="frame-container">
            <div className="inner-frame">
                <div className="inner-frame-content">
                    <div className="header-frame">
                        <div className="title-frame">
                            <div className="title-s">קביעת ערכים לפי גירסה</div>
                        </div>
                        <div className="subtitle-frame">
                            <div className="subtitle-container">
                                <div
                                    className={`subtitle ${currentLawType === 'דין מהותי' ? 'subtitle-active' : 'subtitle-inactive'}`}
                                    onClick={() => setCurrentLawType('דין מהותי')}
                                >
                                    דין מהותי
                                </div>
                                <div
                                    className={`subtitle ${currentLawType === 'דין דיוני' ? 'subtitle-active' : 'subtitle-inactive'}`}
                                    onClick={() => setCurrentLawType('דין דיוני')}
                                >
                                    דין דיוני
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="button-container-s-v">
                        <button className="update-button-s-v" onClick={handleUpdateClick}>עדכון</button>
                    </div>
                    <div className="versions-frame-s-v">
                        <div className="scrollable-table">
                            <Table className="versions-table-s-v" style={{ borderRadius: '16px', border: '0.5px solid rgba(1, 59, 130, 0.50)' }}>
                                <TableHead>
                                    <TableRow>
                                        {["גירסה 4", "גירסה 3", "גירסה 2", "תחילת השאלה", "גירסה 1"].map((version, index) => (
                                            <TableCell
                                                key={index}
                                                className={`version-header version-${index + 1} ${version === 'גירסה 2' ? 'version-3-header' : ''}`}
                                                style={{ fontSize: '16px', fontWeight: 600, lineHeight: '24px' }}
                                            >
                                                {version}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.from({ length: 40 }).map((_, i) => (
                                        <TableRow
                                            key={i}
                                            className={`table-row ${selectedRow === i ? 'selected-row' : ''}`}
                                            onClick={() => setSelectedRow(i)}
                                        >
                                            {["גירסה 4", "גירסה 3", "גירסה 2", "תחילת השאלה", "גירסה 1"].map((version, index) => (
                                                <TableCell
                                                    key={index}
                                                    className={`input-table ${i % 2 === 0 ? 'even' : ''} ${version === 'גירסה 2' ? 'version-3' : ''}`}
                                                >
                                                    <input
                                                        type={version === 'תחילת השאלה' ? 'text' : 'number'}
                                                        value={versionValues[version][i] || ''}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            if (version === 'תחילת השאלה' || validateVersionValue(newValue) || newValue === '') {
                                                                handleValueChange(version, i, newValue);
                                                            }
                                                        }}
                                                        readOnly={version === 'גירסה 1'}
                                                        style={{ borderColor: errorCells[i][index] ? 'red' : '' }}
                                                    />
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SettingIdenticalValues;
