
import React, { useEffect, useState } from 'react';
import '../../style/TopBar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSwitchValue, updateSwitchValue } from '../../Services/TestService';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupIcon from '@mui/icons-material/Group';
import PopupLogout from './popups/popupLogOut';
import downArrowIcon from '../../assets/down-arrow.jpg';

const TopBar = () => {
  const logoImage = require('../../assets/easylaw-logo-01.jpg');
  const arrow_right = require('../../assets/Alt Arrow Right.png');
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const [userName, setUserName] = useState(null);
  const [permissionType, setPermissionType] = useState('');
  const [activeButton, setActiveButton] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const isSwitchActive = useSelector(state => state.Test.isSwitchActive);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleToggleSwitch = () => {
    const newValue = !isSwitchActive;
    dispatch(updateSwitchValue('Switch', newValue));
  };

  const location = useLocation();
  const userString = localStorage.getItem('user');
  let userObject = null;

  if (userString) {
    userObject = JSON.parse(userString);
  }

  useEffect(() => {
    dispatch(fetchSwitchValue('Switch'));
  }, [dispatch]);

  useEffect(() => {
    switch (location.pathname) {
      case '/admin/scores':
        setActiveButton('ציונים');
        break;
      case '/admin/poolOfTest':
        setActiveButton('מאגר');
        break;
      case '/setting':
        setActiveButton('התאמת שאלות');
        break;
      case '/admin/statistic':
        setActiveButton('פתרון');
        break;
      default:
        setActiveButton(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchUser = async () => {
      const userFromStorage = localStorage.getItem('user');
      if (userFromStorage) {
        const parsedUser = JSON.parse(userFromStorage);
        setUserName(parsedUser.name);
        setPermissionType(parsedUser.permission.name);
      }
    };
    fetchUser();
  }, []);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        color: '#17C2D0',
      },
      '&.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#17C2D0',
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: '#013B82',
    },
  }));

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const deleteCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  };

  const handleLogout = () => {
    localStorage.clear();
    deleteCookie("user");
    navigate('/login');
  };

  const handleNavigateToTeam = () => {
    navigate('/all-admin');
    setDropdownOpen(false);
  };

  const handleLogoutClick = () => {
    setLogoutPopupOpen(true);
    setDropdownOpen(false);
  };

  const handleNavigateToTests = () => {
    navigate('/admin/poolOfTest');
  };

  const closeLogoutPopup = () => {
    setLogoutPopupOpen(false);
  };

  return (
    <>
      {userObject && userObject.permission && (userObject.permission.name === 'SuperAdmin' || userObject.permission.name === 'Admin') && (
        <div className="Frame1707478335-t-b">
          <div className="Frame21-t-b">
            <img className="EasylawLogo011-t-b" src={logoImage} alt="Easylaw Logo" />
            <div className="title_top right-side-title">Easylaw - Grades</div>
          </div>

          <div className="Frame1707478442-t-b">
            <div className="Frame1707478345-t-b">
              <div className="SolutionText-t-b">הפתרון שלנו מוכן</div>
              <div className="Switch-t-b">
                <CustomSwitch checked={isSwitchActive} onChange={handleToggleSwitch} color="default" />
              </div>
            </div>
          </div>

          <div className="Frame1208-t-b">
            <div className="Frame603-t-b">
              <div className="Group602-t-b">
                <div className="Name-t-b">{userName}</div>
                <div className="SuperAdmin-t-b">{permissionType}</div>
              </div>

              <div className="VuesaxOutlineArrowDown-t-b" onClick={handleDropdownToggle}>
                <img src={dropdownOpen ? arrow_right : downArrowIcon} className="ArrowDown-t-b" alt="Arrow" />
              </div>

              {dropdownOpen && (
                <div className="DropdownMenu">
                  <div className="DropdownItem" onClick={handleNavigateToTeam}>
                    <GroupIcon className="TeamIcon" style={{ fontSize: '18px' }} />
                    צוות EasyLaw
                  </div>
                  <div className="DropdownItem" onClick={handleLogoutClick}>
                    <LogoutIcon className="LogoutIcon" style={{ fontSize: '18px' }} />
                    התנתקות
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <PopupLogout
        open={isLogoutPopupOpen}
        onClose={closeLogoutPopup}
        onConfirm={handleLogout}
      />
    </>
  );
};

export default TopBar;


