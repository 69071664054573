
import React, { useState } from 'react';
import { MenuItem, Dialog } from '@mui/material';
import { deleteUser } from '../../../Services/UserService';
import '../../../style/displayAllAdmin.css';
import '../../../style/PopupDeleteTest.css';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteAdmin = ({ adminId, onDelete }) => {
    const [openPopup, setOpenPopup] = useState(false);

    const handleOpenPopup = (event) => {
        event.stopPropagation(); 
        setOpenPopup(true);
    };

    const handleClosePopup = () => {
        setOpenPopup(false);
    };

    const handleDelete = async () => {
        await deleteUser(adminId);
        onDelete(); 
        handleClosePopup(); 
    };

    return (
        <>
            <MenuItem onClick={handleOpenPopup}><DeleteIcon className="delete-icon" /></MenuItem>
            <Dialog open={openPopup} onClose={handleClosePopup}>
                <div className="PopupDeleteAdmin-p-d-t">
                    <div className="Frame1707478455-p-d-t">
                        <div className="Frame14-p-d-t">
                            <div className="Title2-p-d-t">אישור מחיקת אדמין</div>
                        </div>
                        <div className="Title-p-d-t">
                            <div className="Message-p-d-t">
                                במחיקת האדמין ימחקו כל הנתונים ולא ניתן יהיה אחר כך לשחזרם
                            </div>
                            <div className="ConfirmationQuestion-p-d-t">
                                האם הינך רוצה למחוק בכל זאת?
                            </div>
                        </div>
                    </div>
                    <div className="Frame22-p-d-t">
                        <div className="Button-p-d-t" onClick={handleDelete}>
                            <div className="ButtonMaster-p-d-t">
                                <div className="ButtonConfirm-p-d-t">
                                    <div className="ButtonText-p-d-t">אישור</div>
                                </div>
                            </div>
                        </div>
                        <div className="Button-p-d-t" onClick={handleClosePopup}>
                            <div className="ButtonMaster-p-d-t">
                                <div className="ButtonCancel-p-d-t">
                                    <div className="ButtonText-p-d-t">ביטול</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};

export default DeleteAdmin;
